
<template>
  <div>
    <Row class="task-amount p-b-10">
      <i-col
        span="12"
        :class="selectedStatus===1?'text-orange':''"
        class="task-amount-border"
      >
        <div @click="handleSearchList(1)">
          <p class="task-amount-title">总计</p>
          <p class="task-amount-subtitle">{{taskAmout.total}}</p>
        </div>
      </i-col>
      <i-col
        span="12"
        :class="selectedStatus===2?'text-orange':''"
      >
        <div @click="handleSearchList(2)">
          <p class="task-amount-title">待派单</p>
          <p class="task-amount-subtitle">{{taskAmout.waitTotal}}</p>
        </div>
      </i-col>
    </Row>

    <Row
      class="p-b-10 p-t-10"
      :gutter="8"
    >
      <i-col span="14">
        <RadioGroup
          v-model="currentTypeValue"
          type="button"
          size="small"
          @on-change="changeTaskType"
        >
          <Radio
            v-for="item in taskTypes"
            :key="item.value"
            :label="item.value"
          >{{item.name}}</Radio>
        </RadioGroup>
      </i-col>
      <i-col span="4">
        <DatePicker
          transfer
          v-model="chooseDate"
          style="width:100%"
          size="small"
          :editable="false"
          clearable
          type="daterange"
          format="yyyy-MM-dd"
          placeholder="选择档期"
          @on-change='changeQueryDate'
        ></DatePicker>
      </i-col>
      <i-col span="4">
        <i-input
          size="small"
          v-model="taskQuery.keyword"
          placeholder="关键字：发起人，品牌"
        ></i-input>
      </i-col>
      <i-col span="2">
        <Button
          type="primary"
          icon="ios-search"
          size="small"
          @click="loadTaskList"
        >搜索</Button>
      </i-col>
    </Row>
    <Table
      stripe
      max-height="620"
      size="small"
      :loading="tableLoading"
      :data="taskList"
      :columns="tableColumns"
    ></Table>

    <div class="paging_style">
      <Page
        size="small"
        :total="total"
        :page-size="taskQuery.pageSize"
        show-total
        show-elevator
        show-sizer
        :page-size-opts="pagesizeSetting"
        @on-page-size-change='changePageSize'
        :current="taskQuery.pageNumber"
        @on-change="handlePageNumberChange"
      ></Page>
    </div>

  </div>
</template>

<script>
import { sysMixins } from '@/assets/mixins/sys'
import { getTaskType } from '@/api/msp/status'
import { getTaskTypeStatistic, getTaskPage } from '@/api/msp/task'
import { GetCurrentSchedule } from '@/utils/dateFormat'
// import { formatMspTaskStatusColor } from '@/utils/tagStatus'

export default {
  mixins: [sysMixins],
  data () {
    return {
      pagesizeSetting: [15, 50, 100, 200, 500],
      taskTypes: [],
      // currentTypeValue: -1,
      chooseDate: [],
      taskList: [],

      taskAmout: {
        total: 0,
        waitTotal: 0
      },
      selectedStatus: 1,
      taskQuery: {
        pageNumber: 1,
        pageSize: 15,
        status: JSON.stringify([0]), // 状态(0 已提交)
        taskitemStatus: JSON.stringify([0, 1, 2, 3, 4, 5, 6]),
        type: null, // 类型(1上刊需求 2维修 3监播 4制作 5核查)
        startDate: '',
        endDate: '',
        keyword: '',
        createDate: '',
        notIncludeDraft: true
      },
      total: 0,
      tableColumns: [],
      tableLoading: false,
      taskTypeShowColumns: new Map([
        [-1, ['taskType', 'taskName', 'createTime', 'createUser', 'schedule', 'statistics', 'operate']], // 全部
        [1, ['taskType', 'taskName', 'createTime', 'createUser', 'schedule', 'statistics', 'operate']], // 上刊
        [2, ['taskType', 'taskName', 'createTime', 'createUser', 'schedule', 'statistics', 'operate']], // 监播
        [3, ['taskType', 'taskName', 'createTime', 'createUser', 'schedule', 'statistics', 'operate']], // 制作
        [5, ['taskType', 'taskName', 'createTime', 'createUser', 'statistics', 'operate']], // 维修
        [7, ['taskType', 'taskName', 'createTime', 'createUser', 'schedule', 'statistics', 'operate']], // 下刊
        [8, ['taskType', 'taskName', 'createTime', 'createUser', 'statistics', 'operate']], // 巡检
        [9, ['taskType', 'taskName', 'createTime', 'createUser', 'statistics', 'operate']], // 维护
        [10, ['taskType', 'taskName', 'createTime', 'createUser', 'statistics', 'operate']], // 画面填充
        [11, ['taskType', 'taskName', 'createTime', 'createUser', 'statistics', 'operate']] // 临时任务
      ])
    }
  },
  computed: {
    taskTypeArray () {
      return this.$store.state.workOrder.taskTypeArray
    },
    currentTypeValue: {
      get () {
        return this.$store.state.workOrder.taskType || -1
      },
      set (val) {
        this.$store.commit('set_task_type', val)
      }
    }
  },
  created () {
    // 如果存在搜索条件状态，则取之
    if (this.$store.state.workOrder.indexSearchParams) {
      this.taskQuery = this.$store.state.workOrder.indexSearchParams
      this.chooseDate = [this.taskQuery.startDate, this.taskQuery.endDate]
    }
    this.initPageData()
    // 设置头部路由标签
    this.setLinkTagArray({ key: 'taskIndex', value: '派单列表' })
    this.setLinkRouterMaps(['taskIndex', () => { this.handleIndexPage() }])
    this.setActivedTagName('taskIndex')
  },
  mounted () {
    this.tableColumns = this.getTableColumns()
  },
  methods: {
    /**
     * 动态生成Table列表
     */
    getTableColumns () {
      const tableColumnArray = {
        taskType: {
          title: '类型',
          align: 'center',
          render: (h, params) => {
            return h('span', this.formatTaskTypeName(params.row.type))
          }
        },
        taskName: { title: '任务名称', align: 'center', key: 'name' },
        createTime: { title: '发起时间', align: 'center', key: 'createTime' },
        createUser: { title: '发起人', align: 'center', key: 'createUserName' },
        schedule: {
          title: '档期',
          align: 'center',
          render: (h, params) => {
            if (params.row.startDate === '' || params.row.endDate === '') {
              return h('span', '-')
            } else {
              return h('span', GetCurrentSchedule(params.row.taskOrder.startDate, params.row.taskOrder.endDate, false))
            }
          }
        },
        // status: {
        //   title: '流程进度',
        //   align: 'center',
        //   render: (h, params) => {
        //     let statusName = '未知'
        //     let color = '#141414'
        //     if (params.row.statusQuantity) {
        //       if (params.row.statusQuantity.waittingQuantity > 0) {
        //         color = '#a59426'
        //         statusName = '派单中'
        //       } else if (params.row.statusQuantity.terminatedQuantity === params.row.statusQuantity.totalQuantity) {
        //         color = '#ef4f4f'
        //         statusName = '不执行'
        //       } else {
        //         statusName = '已派单'
        //         color = '#44bd32'
        //       }
        //     }
        //     return h('Tag', {
        //       props: {
        //         color: color
        //       }
        //     }, statusName)
        //   }
        // },
        statistics: {
          title: '总数/不执行/已派单/执行中/已完成',
          align: 'center',
          render: (h, params) => {
            return h('div', [
              h('span', {
                style: {
                  color: params.row.statusQuantity.draftQuantity + params.row.statusQuantity.waittingQuantity > 0 ? '#ef4f4f' : ''
                }
              }, params.row.statusQuantity.totalQuantity),
              h('span', ` / ${params.row.statusQuantity.terminatedQuantity} / ${params.row.statusQuantity.assignedQuantity} / ${params.row.statusQuantity.executingQuantity} / ${params.row.statusQuantity.finishedQuantity}`)
            ])
          }
        },
        operate: {
          title: '操作',
          align: 'center',
          render: (h, params) => {
            return h('a', {
              on: {
                click: () => {
                  this.handleTask(params.row)
                }
              }
            }, '查看')
          }
        }
      }
      const data = []
      var showColumns = this.taskTypeShowColumns.get(this.currentTypeValue)
      if (!showColumns || showColumns.length === 0) {
        showColumns = this.taskTypeShowColumns.get(1)
      }
      showColumns.forEach(col => data.push(tableColumnArray[col]))

      return data
    },
    initPageData () {
      if (this.taskTypeArray && this.taskTypeArray.length) {
        this.taskTypes = this.taskTypeArray
      } else {
        getTaskType().then(res => {
          this.taskTypes = res
          this.taskTypes.unshift({ value: -1, name: '全部' })

          // 将数据存入state
          this.$store.commit('set_task_type_array', this.taskTypes)
        })
      }

      getTaskTypeStatistic().then(res => {
        let amount = 0
        let quantity = 0
        res.forEach(element => {
          quantity += element.quantity
          amount += element.totalQuantity
        })
        this.taskAmout.waitTotal = quantity
        this.taskAmout.total = amount
      })
      this.loadTaskList()
    },
    loadTaskList () {
      this.tableLoading = true
      // this.taskQuery.createDate = (this.chooseDate === '') ? null : ParseDate(this.chooseDate)
      this.taskQuery.type = this.currentTypeValue === -1 ? null : this.currentTypeValue
      // 保存搜索条件状态
      this.$store.commit('set_index_search_params', this.taskQuery)
      getTaskPage(this.taskQuery).then(res => {
        this.taskList = res.list
        this.total = res.totalRow
      }).finally(() => { this.tableLoading = false })
    },
    changeTaskType (value) {
      this.currentTypeValue = value
      this.taskQuery.pageNumber = 1
      this.tableColumns = this.getTableColumns()
      this.loadTaskList()
    },
    changeQueryDate (date) {
      this.taskQuery.startDate = date[0]
      this.taskQuery.endDate = date[1]
    },
    handlePageNumberChange (page) {
      this.taskQuery.pageNumber = page
      this.loadTaskList()
    },
    changePageSize (pagesize) {
      this.taskQuery.pageNumber = 1
      this.taskQuery.pageSize = pagesize
      this.loadTaskList()
    },
    handleSearchList (param) {
      this.selectedStatus = param
      this.taskQuery.taskitemStatus = (param === 1) ? JSON.stringify([0, 1, 2, 3, 4, 5, 6]) : JSON.stringify([0])
      this.taskQuery.pageNumber = 1
      this.loadTaskList()
    },
    handleTask (item) {
      // 在此做任务处理
      this.$store.commit('set_task_id', item.id)
      this.$store.commit('set_task_type', item.type)
      this.$store.commit('set_task_orderid', item.taskOrder ? item.taskOrder.orderId : null)
      this.handelDetailPage()
      // 设置头部路由标签
      this.setLinkTagArray({ key: 'taskDetail', value: '派单查看' })
      this.setLinkRouterMaps(['taskDetail', () => { this.handelDetailPage() }])
      this.setActivedTagName('taskDetail')
    },
    formatTaskTypeName (typeValue) {
      return this.taskTypes.find(x => x.value === typeValue).name
    },
    // formatStatusTagColor (status) {
    //   return formatMspTaskStatusColor(status)
    // },
    /**
     * 处理页面渲染
     */
    handleIndexPage () {
      this.setShowLeftBlock(false)
      this.setShowRightBlock(true)
      this.setShowMap(false)
      this.setBottomHeight('90%')
      this.setBottomComponent('IndexTable')
      this.setRightComponent('TaskSummary')
    },
    handelDetailPage () {
      this.setLeftComponent('ActionDateView')
      this.setShowLeftBlock(true)
      this.setRightComponent('BasicInfo')
      this.setBottomComponent('TaskItem')
    }
  }
}
</script>
